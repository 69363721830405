.PostNavigation {
    animation: unhideAnimation 0s var(--n, .75s) forwards;
    height: 0;
    overflow: hidden;
}

@keyframes unhideAnimation {
    to   { height: inherit }
}

.Input {
    background: black;
    border: 0;
    outline: 0;
    color: #39ff14;
}

.Form {
    display: inline-block;
}

.typewriterFont span {
    font-family: monospace;
    color:#0000;
    background:
            linear-gradient(-90deg, #39ff14 5px,#0000 0) 10px 0,
            linear-gradient(#39ff14 0 0) 0 0;
    background-size:calc(999*1ch) 200%;
    -webkit-background-clip:padding-box,text;
    background-clip:padding-box,text;
    background-repeat:no-repeat;
}

.typewriter span {
    font-family: monospace;
    color:#0000;
    background:
            linear-gradient(-90deg, #39ff14 5px,#0000 0) 10px 0,
            linear-gradient(#39ff14 0 0) 0 0;
    background-size:calc(999*1ch) 200%;
    -webkit-background-clip:padding-box,text;
    background-clip:padding-box,text;
    background-repeat:no-repeat;
    animation:
            b .7s infinite steps(1),
            t calc(999*.01s) steps(999) forwards;
}

@keyframes t{
    from {background-size:0 200%}
}

@keyframes b{
    50% {background-position:0 -100%,0 0}
}