.App {
  background: black;
  color: #39ff14;
  font-family: monospace;
  position: relative;
  min-height: 100vh;
}

.NavContainer {
  padding-bottom: 2.5rem;
}
