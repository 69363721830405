a {
    color: black;
    position: fixed;
}

a:hover {
    color: black;
}

.Github {
    left: 0;
}

.LinkedIn {
    left: 8ch;
}

.Resume {
    left: 18ch;
}